import { Button, DatePicker, Form, Select } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { filterOption } from "../finanzas/utils";
import { MAIN_PROFILE_ID } from "../../../utils/relevantIds";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";

export const Filtros = ({
  setMovesFilter,
  movesFilter,
  setShowFilters,
  loadingMoves,
  dataCajas,
  handleGetFilteredMoves,
}) => {
  const { config, user, messageApi, alumnos } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [tmpFiltros, setTmpFiltros] = useState({});

  const handleMovesFilter = (type, value) => {
    //dateFrom, dateTo, areaId, accountBoxId, conceptId, paymentMethod

    setTmpFiltros((prevState) => {
      let obj = { ...prevState };

      obj[type] = value;

      return obj;
    });
  };

  const cajasOptions = useMemo(() => {
    if (user?.rol?._id === MAIN_PROFILE_ID) {
      return config?.accountingBoxes?.map((element) => {
        return {
          value: element._id,
          label: element.name,
        };
      });
    } else {
      return dataCajas?.getAccountingBoxesByUserResolver?.map((element) => {
        return {
          value: element.accountBoxData?._id,
          label: element.accountBoxData?.name,
        };
      });
    }
  }, [user, dataCajas, config]);

  const handleAccountBoxValidation = () => {
    if (user?.rol?._id !== MAIN_PROFILE_ID && !tmpFiltros?.accountBoxId) {
      // Si no es admin
      messageApi.info("Es necesario que seleccione una caja");
      return;
    } else {
      const filtros = { ...tmpFiltros };

      setMovesFilter(filtros);
      handleGetFilteredMoves(filtros);
    }
  };

  useEffect(() => {
    setTmpFiltros({ ...movesFilter });
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      name="crearMove"
      requiredMark={false}
      className="form-layout"
    >
      <b style={{ marginBottom: 8 }}>Filtros</b>
      <span className="horizontal gap8">
        <Form.Item label="Desde" className="form-item-half-row">
          <DatePicker
            format={DATE_FORMAT}
            onChange={(date, dateString) => {
              handleMovesFilter("dateFrom", date);
            }}
            value={tmpFiltros?.dateFrom || null}
          />
        </Form.Item>
        <Form.Item label="Hasta" className="form-item-half-row">
          <DatePicker
            format={DATE_FORMAT}
            onChange={(date, dateString) => {
              handleMovesFilter("dateTo", date);
            }}
            value={tmpFiltros?.dateTo || null}
          />
        </Form.Item>
      </span>
      <Form.Item label="Área">
        <Select
          style={{ minWidth: 150 }}
          onChange={(v) => handleMovesFilter("areaId", v)}
          allowClear
          filterOption={filterOption}
          showSearch
          options={config?.areas?.map((element) => {
            return {
              value: element._id,
              label: element.name,
            };
          })}
          value={tmpFiltros?.areaId}
        />
      </Form.Item>

      <Form.Item label="Caja">
        <Select
          style={{ minWidth: 150 }}
          onChange={(v) => handleMovesFilter("accountBoxId", v)}
          allowClear
          filterOption={filterOption}
          showSearch
          options={cajasOptions}
          value={tmpFiltros?.accountBoxId}
        />
      </Form.Item>
      <Form.Item label="Concepto">
        <Select
          style={{ minWidth: 150 }}
          onChange={(v) => handleMovesFilter("conceptId", v)}
          allowClear
          filterOption={filterOption}
          showSearch
          options={config?.concepts?.map((element) => {
            return {
              value: element._id,
              label: element.name,
            };
          })}
          value={tmpFiltros?.conceptId}
        />
      </Form.Item>
      <Form.Item label="Método de pago">
        <Select
          style={{ minWidth: 150 }}
          onChange={(v) => handleMovesFilter("paymentMethodId", v)}
          allowClear
          filterOption={filterOption}
          showSearch
          options={config?.paymentMethods?.map((element) => {
            return {
              value: element._id,
              label: element.name,
            };
          })}
          value={tmpFiltros?.paymentMethodId}
        />
      </Form.Item>
      <Form.Item label="Alumno">
        <Select
          style={{ minWidth: 150 }}
          onChange={(v) => handleMovesFilter("studentId", v)}
          allowClear
          filterOption={filterOption}
          showSearch
          options={alumnos?.map((element) => {
            return {
              value: element._id,
              label: element.fullName,
            };
          })}
          value={tmpFiltros?.studentId}
        />
      </Form.Item>
      <Form.Item className="form-custom-footer">
        <span className="horizontal space-between">
          <Button
            // htmlType="submit"
            className="btn-guardar"
            onClick={handleAccountBoxValidation}
            size="large"
            loading={loadingMoves}
          >
            Buscar
          </Button>
          <Button
            className="btn-cancelar"
            size="large"
            onClick={() => setShowFilters(false)}
          >
            Cancelar
          </Button>
        </span>
      </Form.Item>
    </Form>
  );
};
