import { Button, DatePicker, Form, Input, Select, Switch } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_MOVE } from "../../../graphql/mutation/Finanzas";
import dayjs from "dayjs";
import {
  handleFeeChecked,
  handleFeeValue,
  isFeePaid,
  isFromThisMove,
} from "../../../utils/handleFee";
import { GET_COURSES_BY_STUDENT_ID } from "../../../graphql/query/Cursos";
import { MAIN_PROFILE_ID, PAGO_DE_CUOTA_ID } from "../../../utils/relevantIds";
import { BlobLoader } from "../loader/BlobLoader";
import { GET_COURSE_MOVES_BY_STUDENT_ID } from "../../../graphql/query/Finanzas";
import { makeFloat } from "../../../utils/thousandFormatter";
import {
  addPaymentMethod,
  deletePaymentMethod,
  filterOption,
  getPaymentTotal,
  handlePaymentMethod,
  handlePaymentValue,
  validatePayment,
} from "../../../utils/handlePayment/paymentUtils";
import { FiX } from "react-icons/fi";
import { updateArrayMovimientos } from "../../../utils/updateArrayMovimientos";
import { DATE_FORMAT } from "../../../utils/DATE_FORMAT";
import { calculateCorrection } from "../../../utils/calculateCorrection";

export const UpdateMove = ({ edit = true, modulo = "finanzas" }) => {
  const {
    drawerContent,
    setDrawerContent,
    messageApi,
    config,
    user,
    alumnos,
    setMoves,
    movesPoll,
    setFilteredMoves,
  } = useContext(GlobalContext);

  const [form] = Form.useForm();

  const [hasStudent, setHasStudent] = useState(false);
  const [selectedAlumno, setSelectedAlumno] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [cuotas, setCuotas] = useState([]);
  const [conceptId, setConceptId] = useState("");
  const [paidFees, setPaidFees] = useState([]);
  const [payment, setPayment] = useState([{ paymentMethodId: null, total: 0 }]);

  const [updateMoveResolver, { loading }] = useMutation(UPDATE_MOVE);

  const [getCoursesByStudentId, { loading: loadingById, data: dataById }] =
    useLazyQuery(GET_COURSES_BY_STUDENT_ID, { fetchPolicy: "network-only" });

  const [getCourseMovesByStudentId, { data: dataMoves }] = useLazyQuery(
    GET_COURSE_MOVES_BY_STUDENT_ID,
    {
      fetchPolicy: "network-only",
    }
  );

  const onFinish = (values) => {
    let {
      _id,
      abHistoryId,
      payment: prevPayment,
      accountBoxId,
    } = drawerContent.item;

    if (
      !values.operation ||
      !values.areaId ||
      !values.accountBoxId ||
      !conceptId ||
      !values.currencyId ||
      !values.date
    ) {
      messageApi.info(
        "Los campos operación, área, caja, concepto, total, divisa, métodos de pago y fecha son obligatorios, ingrese un valor para cada uno de ellos"
      );
      return;
    }

    const total = getPaymentTotal(payment);

    values.payment = payment.map((element) => {
      return {
        paymentMethodId: element.paymentMethodId,
        total: makeFloat(element.total),
      };
    });

    if (conceptId !== PAGO_DE_CUOTA_ID && !total) {
      //! Es necesario?
      messageApi.info("Ingrese al menos un metodo de pago y monto, por favor");
      return;
    }

    let date = Number(dayjs(values.date)?.format("x"));

    values.date = date;
    values.total = makeFloat(total);
    values.conceptId = conceptId;

    if (hasStudent) {
      if (!selectedAlumno) {
        return messageApi.info(
          "Debe seleccionar un alumno si decidió asignar uno."
        );
      }

      values.studentId = selectedAlumno;

      if (selectedCourse && conceptId === PAGO_DE_CUOTA_ID) {
        let flag = false;

        let acumulador = 0;

        let obj = {
          courseId: selectedCourse,
          fees: cuotas
            .filter((element) => element.checked)
            .map((cuota) => {
              if (!/^[0-9]+([.,][0-9]{1,2})?$/.test(cuota.value)) {
                messageApi.info(
                  `Por favor, ingrese un monto válido. Cuota ${cuota.feeNumber}`
                );
                flag = true;
                return null;
              } else {
                let number = makeFloat(cuota?.value);

                acumulador += number;
                return {
                  feeNumber: cuota.feeNumber,
                  value: number,
                };
              }
            }),
        };

        if (flag) {
          return;
        }

        if (total !== acumulador) {
          messageApi.info(
            "La suma de las cuotas debe ser igual al total de los métodos de pago"
          );
          return;
        }

        values.total = acumulador;

        values.feeDetail = obj;
      }
    } else {
      values.studentId = null;
      values.feeDetail = null;
    }

    if (conceptId !== PAGO_DE_CUOTA_ID) {
      values.feeDetail = null;
    }

    const isPaymentValid = validatePayment(payment, messageApi);

    if (!isPaymentValid) {
      return;
    }

    let move = { ...values };

    //* crear correction;
    const correctionInput = calculateCorrection(
      drawerContent?.item,
      values.payment,
      config?.accountingBoxes,
      date
    );

    updateMoveResolver({
      variables: {
        moveId: _id,
        moveInput: move,
        correctionInput: correctionInput,
      },
    })
      .then((res) => {
        updateArrayMovimientos(
          res,
          setMoves,
          setFilteredMoves,
          messageApi,
          setDrawerContent,
          movesPoll,
          modulo
        );
      })
      .catch((error) => {
        messageApi.error("Ocurrió  un error al actualizar un movimiento");
      });
  };

  const handleOptions = () => {
    if (user?.rol?._id === MAIN_PROFILE_ID) {
      return config?.accountingBoxes?.map((element) => {
        return {
          value: element._id,
          label: element.name,
        };
      });
    } else {
      return user?.userBoxRelations?.map((element) => {
        return {
          value: element.accountBoxData?._id,
          label: element.accountBoxData?.name,
        };
      });
    }
  };

  const showPaidFee = (element, paidFees) => {
    let flag = false;

    if (
      isFeePaid(element, paidFees) &&
      !isFromThisMove(element, drawerContent?.item?.feeDetail?.fees)
    ) {
      flag = true;
    }

    return flag;
  };

  const enableCheckedChange = (element, paidFees) => {
    let flag = false;

    if (edit && !isFeePaid(element, paidFees)) {
      flag = true;
    }

    if (edit && isFromThisMove(element, drawerContent?.item?.feeDetail?.fees)) {
      flag = true;
    }

    return flag;
  };

  useEffect(() => {
    if (drawerContent) {
      let { item } = drawerContent;

      if (item) {
        if (item.studentId) {
          setHasStudent(true);
          setSelectedAlumno(item.studentId);
        }

        if (item.concept?._id) {
          setConceptId(item.concept._id);
        }

        if (item.feeDetail) {
          setSelectedCourse(item.feeDetail.courseId);
        }

        if (item.payment) {
          setPayment(item.payment);
        }

        form.setFieldsValue({
          operation: item.operation,
          areaId: item.area?._id,
          accountBoxId: item.accountBoxId,
          currencyId: item.currency?._id,
          description: item.description,
          date: dayjs(item.date, "x"),
        });
      }
    }
  }, [drawerContent]);

  useEffect(() => {
    if (selectedAlumno) {
      getCoursesByStudentId({
        variables: {
          studentId: selectedAlumno,
        },
      });
    }
  }, [selectedAlumno]);

  useEffect(() => {
    if (selectedCourse && selectedAlumno) {
      getCourseMovesByStudentId({
        variables: {
          studentId: selectedAlumno,
          courseId: selectedCourse,
        },
      });
    }
  }, [selectedCourse, selectedAlumno]);

  useEffect(() => {
    try {
      if (selectedCourse) {
        let filtro = dataById?.getCoursesByStudentIdResolver?.find(
          (element) => element?.courseInfo?._id === selectedCourse
        )?.courseInfo;

        if (filtro) {
          let array = [];
          let cantidad = Number(filtro.cost?.amountOfFees);
          for (let index = 0; index < cantidad; index++) {
            let cuotaBuscada = drawerContent?.item?.feeDetail?.fees?.find(
              (cuota) => cuota.feeNumber === index
            );

            array.push({
              checked: drawerContent?.item?.feeDetail?.fees
                ? Number.isInteger(cuotaBuscada?.feeNumber)
                  ? true
                  : false
                : false,
              feeNumber: index,
              value: cuotaBuscada?.value ? cuotaBuscada.value : 0,
            });
          }

          setCuotas(array);
        }
      } else {
        setCuotas([]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedCourse, dataById, drawerContent.item]);

  useEffect(() => {
    if (Array.isArray(dataMoves?.getCourseMovesByStudentId)) {
      let array = [];
      dataMoves.getCourseMovesByStudentId.forEach((move) =>
        move?.feeDetail?.fees?.forEach((element) =>
          array.push({ ...element, moveId: move._id })
        )
      );

      setPaidFees(array);
    }
  }, [dataMoves]);

  const paymentOptions = useMemo(() => {
    return config?.paymentMethods?.map((element) => {
      return {
        value: element._id,
        label: element.name,
      };
    });
  }, [config]);

  return (
    <Form
      form={form}
      layout="vertical"
      name="updateMove"
      requiredMark={false}
      onFinish={(v) => onFinish(v)}
      disabled={!edit}
      className="form-layout"
    >
      <div className={edit ? "form-content" : "form-content-details"}>
        <span className="horizontal">
          <Form.Item
            label="Operación"
            name="operation"
            className="form-item-half-row"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              options={[
                { value: "INGRESO", label: "INGRESO" },
                { value: "EGRESO", label: "EGRESO" },
              ]}
              disabled={true}
            />
          </Form.Item>
          <Form.Item
            label="Área"
            name="areaId"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              options={config?.areas?.map((element) => {
                return { value: element._id, label: element.name };
              })}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            label="Caja"
            name="accountBoxId"
            className="form-item-half-row"
            rules={[{ required: true, message: "Campo requerido" }]}
          >
            <Select
              disabled={edit}
              options={handleOptions()}
              showSearch
              filterOption={filterOption}
            />
          </Form.Item>
          <Form.Item label="Concepto" className="form-item-half-row">
            <Select
              options={config?.concepts?.map((element) => {
                return { value: element._id, label: element.name };
              })}
              showSearch
              filterOption={filterOption}
              value={conceptId}
              onChange={(v) => setConceptId(v)}
            />
          </Form.Item>
        </span>
        <span className="horizontal">
          <Form.Item
            label="Divisa"
            name="currencyId"
            className="form-item-half-row"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              options={config?.currencies?.map((element) => {
                return {
                  value: element._id,
                  label: `${element.symbol} - ${element.name}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Fecha"
            name="date"
            initialValue={dayjs()}
            className="form-item-half-row"
            rules={[
              {
                required: true,
                message: "Campo requerido",
              },
            ]}
          >
            <DatePicker format={DATE_FORMAT} disabled={true} />
          </Form.Item>
        </span>
        <Form.Item label="Descripción" name="description">
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>
        <Form.Item label="Métodos de pago">
          <div className="columna">
            {payment?.map((metodo, index) => {
              return (
                <span className="horizontal" key={index}>
                  <Select
                    style={{ width: "60%" }}
                    options={paymentOptions}
                    showSearch
                    filterOption={filterOption}
                    value={metodo?.paymentMethodId}
                    onChange={(v) => handlePaymentMethod(setPayment, index, v)}
                  />
                  <Input
                    style={{ flex: 1 }}
                    type="number"
                    value={metodo.total}
                    onChange={(v) =>
                      handlePaymentValue(setPayment, index, v.target.value)
                    }
                  />
                  <span
                    className="pointer"
                    onClick={() => deletePaymentMethod(setPayment, index)}
                  >
                    <FiX size={14} />
                  </span>
                </span>
              );
            })}
            <span className="horizontal">
              <Button
                style={{ marginLeft: "auto" }}
                onClick={() => addPaymentMethod(setPayment)}
              >
                Agregar otro método
              </Button>
            </span>
          </div>
        </Form.Item>
        <div className="move-student-section">
          <span className="horizontal">
            <p>Asignar a un alumno</p>
            <Switch checked={hasStudent} onChange={(v) => setHasStudent(v)} />
          </span>
          {hasStudent && (
            <Form.Item label="Alumno">
              <Select
                options={alumnos?.map((student) => {
                  return { value: student._id, label: student.fullName };
                })}
                value={selectedAlumno}
                showSearch
                filterOption={filterOption}
                onChange={(v) => setSelectedAlumno(v)}
              />
            </Form.Item>
          )}
          {hasStudent &&
            selectedAlumno &&
            conceptId === PAGO_DE_CUOTA_ID &&
            (loadingById ? (
              <BlobLoader />
            ) : (
              <>
                <Form.Item label="Curso">
                  <Select
                    options={dataById?.getCoursesByStudentIdResolver?.map(
                      (element) => {
                        return {
                          value: element.courseInfo?._id,
                          label: `${element.courseInfo?.name} - ${element.turnInfo?.name}`,
                        };
                      }
                    )}
                    showSearch
                    filterOption={filterOption}
                    value={selectedCourse}
                    onChange={(v) => setSelectedCourse(v)}
                  />
                </Form.Item>
                {selectedCourse && cuotas?.length > 0 && (
                  <Form.Item label="Coutas">
                    {cuotas.map((element) => {
                      return (
                        <span
                          className="horizontal"
                          key={element.feeNumber}
                          style={{ marginBottom: "4px" }}
                        >
                          <span
                            className="contenedor-cuota"
                            style={
                              // isFeePaid(element, paidFees) &&
                              // !isFromThisMove(
                              //   element,
                              //   drawerContent?.item?.feeDetail?.fees
                              // )
                              showPaidFee(element, paidFees)
                                ? { backgroundColor: "#0000000A" }
                                : element?.checked
                                ? {
                                    color: "var(--detail-color)",
                                    border: "1px solid var(--detail-color)",
                                  }
                                : {}
                            }
                            onClick={() =>
                              enableCheckedChange(element, paidFees) &&
                              setCuotas((prevState) =>
                                handleFeeChecked(prevState, element.feeNumber)
                              )
                            }
                          >
                            {element.feeNumber + 1}
                          </span>
                          <Input
                            type="number"
                            value={element.value}
                            disabled={
                              !edit ||
                              (isFeePaid(element, paidFees) &&
                                !isFromThisMove(
                                  element,
                                  drawerContent?.item?.feeDetail?.fees
                                ))
                            }
                            onChange={(v) =>
                              setCuotas((prevState) =>
                                handleFeeValue(
                                  prevState,
                                  element.feeNumber,
                                  v.target.value,
                                  messageApi
                                )
                              )
                            }
                          />
                        </span>
                      );
                    })}
                  </Form.Item>
                )}
              </>
            ))}
        </div>
        <span className="horizontal">
          <Form.Item
            label="Total"
            className="form-item-half-row"
            style={{ marginLeft: "auto" }}
          >
            <Input
              disabled={conceptId === PAGO_DE_CUOTA_ID || !edit}
              value={getPaymentTotal(payment)}
            />
          </Form.Item>
        </span>
      </div>
      {edit && (
        <Form.Item className="form-custom-footer">
          <span className="horizontal space-between">
            <Button
              htmlType="submit"
              className="btn-guardar"
              size="large"
              loading={loading}
            >
              Guardar
            </Button>
            <Button
              className="btn-cancelar"
              size="large"
              onClick={() => {
                setDrawerContent({
                  visible: false,
                  content: "",
                  item: {},
                });
              }}
            >
              Cancelar
            </Button>
          </span>
        </Form.Item>
      )}
    </Form>
  );
};
