import { gql } from "@apollo/client";

export const GET_ACCOUNTING_BOXES_BY_USER_ID = gql`
  query getAccountingBoxesByUserResolver($userId: String) {
    getAccountingBoxesByUserResolver(userId: $userId) {
      _id
      createdAt
      isActive
      accountBoxData {
        _id
        name
      }
    }
  }
`;

export const GET_ACCOUTING_BOXES_HISTORY = gql`
  query getABHistoryResolver($accountBoxId: String, $page: Int) {
    getABHistoryResolver(accountBoxId: $accountBoxId, page: $page) {
      history {
        _id
        dateFrom
        dateTo
        status
        accountBoxId
        openingData {
          paymentMethodId
          amount
        }
        openingTotal
        closingTotal
      }
      pagination {
        currentPage
        totalItems
      }
    }
  }
`;

export const GET_MOVES_DETAIL = gql`
  query GetMovesDetailResolver($accountBoxId: String, $abHistoryId: String) {
    getMovesDetailResolver(
      accountBoxId: $accountBoxId
      abHistoryId: $abHistoryId
    ) {
      _id
      accountingBox
      moves {
        _id
        operation
        description
        date
        total
        createdAt
        concept {
          _id
          name
          description
        }
        currency {
          _id
          name
          symbol
        }
        area {
          _id
          name
          description
        }
        feeDetail {
          courseId
          fees {
            feeNumber
            value
          }
        }
        studentId
        payment {
          paymentMethodId
          total
        }
        accountBoxId
        accountingBox {
          _id
          name
          type
          description
          openingDate
          closingDate
          createdAt
          abHistoryId
        }
        abHistoryId
      }
      corrections {
        idMove
        abHistoryId
        payment {
          paymentMethodId
          total
        }
        total
        date
        accountBoxId
        operation
      }
      transfersIn {
        _id
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
      transfersOut {
        _id
        abFrom
        abTo
        transferInput {
          paymentMethodId
          amount
        }
        total
        createdAt
        userId
      }
      openingData {
        paymentMethodId
        amount
      }
      openingTotal
    }
  }
`;

export const GET_TRANSFER_DETAIL = gql`
  query getMovesByTransferResolver($transferId: String) {
    getMovesByTransferResolver(transferId: $transferId) {
      _id
      operation
      description
      date
      total
      createdAt
      concept {
        _id
        name
        description
      }
      currency {
        _id
        name
        symbol
      }
      area {
        _id
        name
        description
      }
      feeDetail {
        courseId
        fees {
          feeNumber
          value
        }
      }
      studentId
      payment {
        paymentMethodId
        total
      }
      accountBoxId
    }
  }
`;
